import React, { Component } from 'react'
import { Fade } from 'react-reveal'
import classNames from 'classnames'

import { RowLayout } from '../../../components/layout'
import Page from '../../templates/Page'
import PageSection from '../../templates/PageSection'
import ActionButton from '../../components/ActionButton'

import './audience.css'
import devicesImage from '../../assets/old-images/audience/audience-hero.png'
import embeddedPlayerImage from '../../assets/old-images/audience/now-playing.png'
import directoryImage from '../../assets/old-images/audience/notebook.png'
import listeningAppsImage from '../../assets/old-images/audience/cells.png'
import appStoreBadge from '../../assets/old-images/home/app-store-badge.svg'
import googlePlayBadge from '../../assets/old-images/home/google-play-badge.png'

class Audience extends Component {
  render() {
    return (
      <Page
        className={classNames('audience', this.props.className)}
        headerTitle="Building Your Audience"
        headerSubtitle="Join the radio revolution and put your station in front of thousands of listeners."
        headerContent={headerContent}
      >
        <PageSection className={classNames('website-integration')}>
          <RowLayout isResponsive breakPoint={1000} itemSpacing={'4vw'}>
            <div className={classNames('column', 'padded-section-content')}>
              <Fade top duration={700} fraction={0.9}>
                <h1 className={classNames('section-title')}>
                  Website Integration
                </h1>
                <p className={classNames('section-text')}>
                  Already have a website? Just embed your player widget so your
                  listeners can tune in.
                </p>
              </Fade>
            </div>
            <Fade bottom duration={700} fraction={0.9}>
              <img
                src={embeddedPlayerImage}
                alt="Embeddable player for your website"
              />
            </Fade>
          </RowLayout>
        </PageSection>

        <PageSection
          className={classNames('directory-listing', 'with-bottom-image')}
        >
          <RowLayout
            isResponsive
            breakPoint={1000}
            itemSpacing={'4vw'}
            className={classNames('reverse-row')}
          >
            <div className={classNames('column', 'padded-section-content')}>
              <Fade top duration={700} fraction={0.9}>
                <h1 className={classNames('section-title')}>
                  Directory Listing
                </h1>
                <p className={classNames('section-text')}>
                  Your station is instantly listed and searchable in our popular
                  listening directory. Enjoy the network effect as listeners use
                  genre and text search to find your station quickly.
                </p>
                <ActionButton
                  title="Browse Stations"
                  route="/listen"
                  color="blue"
                />
              </Fade>
            </div>
            <Fade bottom duration={700} fraction={0.9}>
              <img
                className={classNames('image-at-bottom-edge')}
                src={directoryImage}
                alt="Station directory"
              />
            </Fade>
          </RowLayout>
        </PageSection>

        <PageSection
          className={classNames('listening-apps', 'with-bottom-image')}
        >
          <RowLayout isResponsive breakPoint={1000} itemSpacing={'4vw'}>
            <div className={classNames('column', 'padded-section-content')}>
              <Fade top duration={700} fraction={0.9}>
                <h1 className={classNames('section-title')}>
                  Live365 Listening Apps
                </h1>
                <p className={classNames('section-text')}>
                  Our iPhone and Android apps are free and available in the App
                  Store and on Google Play.
                </p>
                <div className={classNames('row', 'store-badges')}>
                  <a href="https://itunes.apple.com/us/app/live365-radio-music-talk/id1228431134?mt=8">
                    <img alt="Download on the App Store" src={appStoreBadge} />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.live365&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    <img alt="Get it on Google Play" src={googlePlayBadge} />
                  </a>
                </div>
              </Fade>
            </div>
            <Fade bottom duration={700} fraction={0.9}>
              <img
                className={classNames('image-at-bottom-edge')}
                src={listeningAppsImage}
                alt="Live365 apps for mobile devices"
              />
            </Fade>
          </RowLayout>
        </PageSection>

        {this.props.children}
      </Page>
    )
  }
}

const headerContent = (
  <div className="row">
    <img src={devicesImage} alt="Live365 is available on all your devices" />

    {/*<p className={classNames('body-text')}>
      Live365 puts your station in front of thousands of listeners,
      we’re bringing you tools to engage your audience and grow your
      listenership.
    </p>*/}
  </div>
)

export default Audience
